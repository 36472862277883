<template>
  <v-navigation-drawer
    white
    app
    clipped
    left
    :permanent="permanent"
    :width="width"
  >
    <v-list>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="{ path: item.to }"
          @click="closeDrawer"
        >
          <v-list-item-icon>
            <v-icon v-text="item.action"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import i18n from '../i18n'

export default {
  name: "Navbar",
  methods: {
    closeDrawer() {
      this.$store.commit("toggleDrawerState", false);
    },
  },
  computed: {
    permanent() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return this.$store.state.drawerState;
        case "md":
        case "lg":
        case "xl":
        default:
          return true;
      }
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "100%";
        case "md":
        case "lg":
        case "xl":
          return 356;
        default:
          return 512;
      }
    },
  },
  data: () => ({
    items: [
      {
        action: "mdi-account",
        title: i18n.t('manage-account'),
        to: "/manage-account",
      },
      {
        action: "mdi-security",
        title: i18n.t('privacy-policy'),
        to: "/privacy-policy",
      },
      {
        action: "mdi-information",
        title: i18n.t('terms-of-use'),
        to: "/terms",
      },
    ],
  }),
};
</script>
