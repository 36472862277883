<template>
  <div>
    <v-row justify="center">
      <v-btn class="ma-2" @click="dialog = true" elevation="0">
        <v-icon dark size="24px" small> mdi-translate </v-icon>
        <p class="ma-1">{{ selectedLang }}</p>
      </v-btn>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>{{ $t("select-lang") }}</v-card-title>
          <v-card-text>
            <v-select
              :items="langCodes"
              :label="langCode"
              v-model="selectedLang"
              item-value="code"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                changeLanguage(selectedLang);
                dialog = false;
              "
              >{{ $t("save") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import store from "../plugins/vuex";

const langCodeList = [
  { code: "ar", text: "عربي" },
  { code: "en", text: "English" },
  { code: "es", text: "Español" },
  { code: "fr", text: "French" },
  { code: "pt", text: "Português" },
  { code: "ru", text: "Pусский" },
  { code: "tr", text: "Türkçe" },
];

export default {
  name: "LanguageSelect",
  data: () => ({
    selectedLang: store.state.langCode,
    dialog: false,
    langCodes: langCodeList,
  }),
  methods: {
    changeLanguage(s) {
      this.$store.commit("changeLanguage", s);
      console.log("hey", this.$store.getters.langCode);
      window.location.reload();
    },
  },
  computed: {
    langCode: {
      get() {
        return this.$store.getters.langCode;
      },
      set(v) {
        return this.$store.commit("changeLanguage", v);
      },
    },
  },
};
</script>
