import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import vuetify from './plugins/vuetify'
import store from './plugins/vuex'
import i18n from './i18n'

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,

  computed: {
    drawerState: {
      get () { return this.$store.getters.drawerState },
      set (v) { return this.$store.commit('toggleDrawerState', v) }
    },
    langCode: {
      get () { return this.$store.getters.langCode },
      set (v) { return this.$store.commit('changeLanguage', v) }
    },
  },

  i18n,
  render: h => h(App)
}).$mount('#app')
