import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function getBrowserLocales(options = {}) {
    const defaultOptions = {
        languageCodeOnly: false,
    };
    const opt = {
        ...defaultOptions,
        ...options,
    };
    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;
    if (!browserLocales) {
        return undefined;
    }
    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();
        return opt.languageCodeOnly
            ? trimmedLocale.split(/-|_/)[0]
            : trimmedLocale;
    });
}

const supportedLangCodes = [
    'en',
    'tr',
    'ar',
    'pt',
    'es',
    'fr',
    'ru'
]

const browserLangCodes = getBrowserLocales({ languageCodeOnly: true });
var initLangCode;

if (browserLangCodes.length > 0) {
    const firstLangCode = browserLangCodes[0];
    if (supportedLangCodes.includes(firstLangCode)) {
        initLangCode = firstLangCode;
    } else {
        initLangCode = 'en';
    }
} else {
    initLangCode = 'en';
}

console.log('init lang code: ', initLangCode);

if (localStorage.langCode) {
    initLangCode = localStorage.langCode;
}

console.log('local storage lang code: ', localStorage.langCode);

export default new Vuex.Store({
    state: {
        drawerState: false,
        langCode: initLangCode,
    },
    mutations: {
        toggleDrawerState(state, data) {
            state.drawerState = data
        },
        changeLanguage(state, data) {
            localStorage.langCode = data;
            state.langCode = data
        }
    },
    getters: {
        drawerState: (state) => state.drawerState,
        langCode: (state) => state.langCode
    }
})
