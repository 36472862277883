<template>
  <v-app>
    <Appbar></Appbar>
    <Navbar></Navbar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <v-col :md="md" :offset-md="offset_md">
            <router-view> </router-view>
        </v-col>
      </v-container>
    </v-main>

    <Footer></Footer>
  </v-app>
</template>

<script>
import Appbar from "./components/Appbar";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

export default {
  name: "App",

  computed: {
    md() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 12;
        case "md":
          return 10;
        case "lg":
          return 8;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
    offset_md() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 0;
        case "md":
          return 1;
        case "lg":
          return 2;
        case "xl":
          return 3;
        default:
          return 3;
      }
    },
  },
  components: {
    Appbar,
    Navbar,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>
