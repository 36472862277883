<template>
  <v-app-bar class="white" app dense clipped-left elevation="1">
    <router-link to="/" style="text-decoration: none; color: inherit;">
      <v-row align="center">
        <v-img
          class="rounded-lg ma-2"
          max-height="38"
          max-width="38"
          a
          src="@/assets/monotony-icon-120px.png"
        ></v-img>
        <h3>{{ $t('help-center') }}</h3>
      </v-row>
    </router-link>
    <v-spacer></v-spacer>
    <LanguageSelect></LanguageSelect>
    <v-app-bar-nav-icon
      @click="toggleDrawer"
      v-show="!$vuetify.breakpoint.mdAndUp"
    ></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
import LanguageSelect from "./LanguageSelect.vue";
export default {
  name: "Appbar",

  components: {
    LanguageSelect,
  },
  data: () => ({
    //
  }),
  methods: {
    toggleDrawer() {
      this.$store.commit("toggleDrawerState", !this.$store.getters.drawerState);
    },
  },
  computed: {
    drawerState: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(v) {
        return this.$store.commit("toggleDrawerState", v);
      },
    },
  },
};
</script>
