<template>
  <v-container>
    <h1>{{ $t("manage-account") }}</h1>
    <br />
    <v-expansion-panels class="mb-6" focusable>
      <v-expansion-panel v-for="(rootItem, i) in root" :key="i">
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          {{ rootItem.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ rootItem.text }}
          <br />
          <ol>
            <div v-for="(item, j) in rootItem.sub" v-bind:key="j">
              <v-card
                v-if="item.info"
                class="pa-2 ma-1"
                color="blue lighten-5"
                outlined
                >{{ item.text }}</v-card
              >
              <li v-else>{{ item.text }}</li>
            </div>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import i18n from "../i18n";
export default {
  name: "ManageAccount",

  data: () => ({
    root: [
      {
        title: i18n.t("how-to-delete"),
        sub: [
          {
            text: i18n.t("go-to-settings"),
          },
          {
            text: i18n.t("go-to-account"),
          },
          {
            text: i18n.t("go-to-delete-account"),
          },
          {
            info: true,
            text: i18n.t("delete-account-info"),
          },
          {
            text: i18n.t("go-to-delete-account"),
          },
          {
            text: i18n.t("send-feedback-or-skip"),
          },
          {
            text: i18n.t("go-to-delete-account"),
          },
        ],
      },
    ],
  }),
};
</script>
