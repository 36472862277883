<template>
  <v-container>
    <div v-for="item in root" v-bind:key="item.name">
      <h3>{{ item.title }}</h3>
      <div v-for="par in item.text" v-bind:key="par">
        <p>{{ par }}</p>
      </div>
      <ul v-for="item in item.sub" v-bind:key="item.name">
        <a v-if="item.href" v-href="item.href"><li>
          <div v-for="par in item.text" v-bind:key="par">
            <p>{{ par }}</p>
          </div>
          </li></a>
        <li v-else>
          <div v-for="par in item.text" v-bind:key="par">
            <p>{{ par }}</p>
          </div>
        </li>
        <ul v-for="item in item.sub" v-bind:key="item.name">
          <a v-if="item.href" v-href="item.href"><li>
            <div v-for="par in item.text" v-bind:key="par">
              <p>{{ par }}</p>
            </div>
            </li></a>
          <li v-else>
            <div v-for="par in item.text" v-bind:key="par">
              <p>{{ par }}</p>
            </div>
          </li>
        </ul>
      </ul>
      <br>
    </div>
  </v-container>
</template>

<script>
import store from '../plugins/vuex';
const lang = require("../assets/privacy-policy/" + store.state.langCode + ".json");
export default {
  name: "Terms",
  data: () => lang,
};
</script>
