<template>
  <v-container>
    <p>{{ $t('welcome') }}</p>
  </v-container>
</template>

<script>
export default {
  name: "Home",

  data: () => ({
    //
  }),
};
</script>
